import { createRouter, createWebHistory } from '@ionic/vue-router';
import store from '@/store'

const routes = [
    {
        path: '/',
        redirect: '/select'
    },
    {
        path: '/login',
        component: () => import('@/views/Login.vue')
    },
    {
        path: '/select',
        meta: { requiresAuth: true },
        component: () => import('@/views/Select.vue')
    },
    {
        path: '/efir',
        meta: { requiresAuth: true },
        component: () => import('@/views/Efir.vue')
    },
    {
        path: '/load',
        meta: { requiresAuth: true },
        component: () => import('@/views/Load.vue')
    },
    {
        path: '/news',
        meta: { requiresAuth: true },
        component: () => import('@/views/News.vue')
    },
    {
        path: '/stream',
        meta: { requiresAuth: true },
        component: () => import('@/views/Stream.vue')
    },
    {
        path: '/videos',
        meta: { requiresAuth: true },
        component: () => import('@/views/Videos.vue')
    },
    {
        path: '/add-news/:type',
        meta: { requiresAuth: true },
        component: () => import('@/views/AddNews.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.requiresAuth)) {
        const userId = store.getters['user/isLoggedIn'];
        if (userId) {
            if (!store.getters['user/getUser']) {
                console.log(userId);
                store.dispatch('user/user');
            }
            next()
            return
        }
        next('/login')
    } else {
        next()
    }
});

export default router
