import axios from 'axios'
import {GET_USER_URL, LOGIN_URL} from "../../constant/api";

const state = () => ({
    status: '',
    user: null,
    id: localStorage.getItem('user') || '',
})

// getters
const getters = {
    isLoggedIn: state => state.id,
    authStatus: state => state.status,
    getUser: state => state.user,
}

// actions
const actions = {
    login({commit}, user){
        return new Promise((resolve) => {
            console.log(user);
            commit('auth_request');
            axios({
                method: 'POST',
                url: LOGIN_URL,
                data: 'email=' + user.userName + '&password=' + user.userPassword,
                headers: {
                    'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
                }
            }).then(res => {
                var userData = res.data;
                if(userData.success) {
                    localStorage.setItem('user', userData.id);
                    commit('auth_success', userData);
                }
                resolve(res);
            }).catch(error => {
                console.log(error);
                resolve(error);
            });
        })
    },

    user({commit}){
        return new Promise((resolve) => {
            axios({
                method: 'POST',
                url: GET_USER_URL,
                data: 'id=' + (localStorage.getItem('user') || ''),
                headers: {
                    'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
                }
            }).then(res => {
                var userData = res.data;
                if(userData.success) {
                    localStorage.setItem('user', userData.id);
                    commit('auth_success', userData);
                } else {
                    commit('logout')
                    localStorage.removeItem('user')
                }
                resolve(res);
            }).catch(error => {
                commit('logout')
                localStorage.removeItem('user')
                resolve(error);
            });
        })
    },

    logout({commit}){
        return new Promise((resolve) => {
            commit('logout')
            localStorage.removeItem('user')
            resolve()
        })
    }
}

// mutations
const mutations = {
    auth_request(state){
        state.status = 'loading'
    },
    auth_success(state, user){
        state.status = 'success';
        state.user = user;
        state.id = user.id
    },
    user_set(state, user) {
        state.user = user;
        state.id = user.id;
    },
    auth_error(state){
        state.status = 'error'
    },
    logout(state){
        state.status = ''
        state.user = {};
        state.id = '';
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
