<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
  }
});
</script>

<style>
.control-head .icon-inner svg * {
    color: #fff;
    stroke: #fff !important;
}
ion-button {
    height: 48px;
}
.button-type {
    height: 48px;
    width: 100%;
    background: #3880ff;
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.06em;
    text-transform: uppercase;
}
.button-type.ion-color-danger {
    background: #b00707;
}
.set-cam:hover::after,
.return:hover::after,
.button-type:hover::after {
    content: none;
}
</style>
