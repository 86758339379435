export const API_BASE = 'https://127.0.0.1:8000/admin/';
export const LOGIN_URL = 'https://media.ukraine-inform.com/auth/login-app';
export const GET_USER_URL = 'https://media.ukraine-inform.com/auth/login-app-user';
export const GET_USER_BUTTON = 'https://media.ukraine-inform.com/helps/get-user-buttons';
export const GET_CAT_LIST = 'https://media.ukraine-inform.com/helps/get-catlist';
export const BASE_SITE_URL = 'https://unt.ua';
export const BASE_SITE = 1;

export function makeid() {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < 10; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
